import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Divider, useTheme } from '@material-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
  FdTypography,
  FdTooltip,
  FdIcons,
  FdButton,
  FdModal,
  FdChip,
  FdAlert,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import Countdown from 'react-countdown';
import { isPast, minutesToMilliseconds } from 'date-fns/esm';
import {
  getLabTime,
  listLabInstancesByModulePart,
  getLabPrototype,
  listVPNVMsByLabInstanceId,
} from '../../graphql/queries';
import { startLab, stopLab } from '../../graphql/mutations';
import {
  formatMinutesToHours,
  getDateTimeZoneFormatted,
} from '../../shared/utils/dateUtils';
import { getLabStatusColor } from '../../shared/utils/getStatusColor';
import ContentWithLabel from '../ContentWIthLabel';
import { successToastMessage } from '../../shared/utils/toast';

const LabControl = ({
  assessmentId,
  activeTask,
  user,
  labId,
  modulePartId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openRestartModal, setRestartModal] = useState(false);
  const [labStatus, setLabStatus] = useState(undefined);
  const [activeLabInstance, setActiveLabInstance] = useState(undefined);
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const theme = useTheme();
  const { Alarm, AvTimer, Warning, InfoOutlined } = FdIcons;

  const {
    data: labPrototypeTime,
    loading: refetchLabTimeLoading,
    refetch: refetchLabTime,
  } = useQuery(gql(getLabTime), {
    variables: {
      labInstanceId: activeLabInstance?.id,
      limit: 1000,
    },
    skip: !activeLabInstance,
  });

  const { data: labPrototype, loading: labPrototypeLoading } = useQuery(
    gql(getLabPrototype),
    {
      variables: {
        id: labId,
        limit: 1000,
      },
      onCompleted: () => {
        setTimeout(() => {
          refetchLabTime();
        }, 1000);
      },
      skip: !labId,
    },
  );

  const { data: VPNVMsData, refetch: refetchListVPNVMs } = useQuery(
    gql(listVPNVMsByLabInstanceId),
    {
      variables: {
        labInstanceId: activeLabInstance?.id,
        filter: {
          status: {
            eq: 'ACTIVE',
          },
        },
        limit: 1000,
      },
      skip: !activeLabInstance,
    },
  );

  const stopPollingProcess = () => {
    // eslint-disable-next-line no-use-before-define
    stopPolling();
    setPollingInProgress(false);
    refetchLabTime();
    if (activeLabInstance?.id) {
      setTimeout(async () => {
        await refetchListVPNVMs();
      }, 1000);
    }
  };

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
    refetch: refetchListLabInstances,
  } = useQuery(gql(listLabInstancesByModulePart), {
    variables: {
      modulePartId: activeTask?.modulePartId,
      userId: { eq: user?.username },
      filter: {
        assessmentId: {
          eq: assessmentId,
        },
        status: {
          ne: 'DELETED',
        },
      },
      limit: 1000,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: async (_data) => {
      if (labId && modulePartId) {
        if (
          activeTask?.modulePart?.expiry &&
          isPast(new Date(activeTask?.modulePart?.expiry))
        ) {
          setLabStatus('Expired');
          return;
        }

        const labPrototypeStatus = labPrototype?.getLabPrototype?.status;
        const labInstancesList = _data?.byModulePartId?.items;
        const labInstance =
          labInstancesList.length > 0 &&
          labInstancesList[labInstancesList.length - 1];

        switch (labPrototypeStatus) {
          case 'BUILD_REQUESTED':
          case 'BUILDING':
          case 'REBUILD_REQUESTED': {
            setLabStatus('Building');
            stopPolling();
            break;
          }
          case 'DELETED': {
            setLabStatus('Deleted');
            stopPolling();
            break;
          }
          case 'READY': {
            if (labInstance) {
              setActiveLabInstance(labInstance);

              // When lab started in assessor, participants can't stop or pause it.
              if (labInstance.status === 'READY') {
                setLabStatus('Ready');
                stopPollingProcess();
                refetchLabTime();
              } else if (
                ['DELETED', 'DELETE_FAILED'].includes(labInstance.status)
              ) {
                setLabStatus('Expired');
                stopPollingProcess();
              } else if (
                labInstance.status === 'DELETED' &&
                activeTask?.modulePart?.expiry &&
                !isPast(new Date(activeTask?.modulePart?.expiry))
              ) {
                setLabStatus('Not Started');
                stopPollingProcess();
              } else if (
                labInstance.status === 'DELETED' &&
                activeTask?.modulePart?.expiry &&
                isPast(new Date(activeTask?.modulePart?.expiry))
              ) {
                setLabStatus('Expired');
                stopPollingProcess();
              } else if (labInstance.status === 'OFF') {
                if (labStatus === 'Restarting') {
                  // start lab when restarting
                  setLabStatus('Starting');
                  // eslint-disable-next-line no-use-before-define
                  startLabMutation({
                    variables: {
                      labPrototypeId: labId,
                      modulePartId,
                      assessmentId,
                    },
                  });
                  return;
                }
                setLabStatus('Shutdown');
                // if timeRemaining is more than 1 minute
                // then trigger stopPolling to make the lab shutdown,
                // otherwise this lab is going to be deleted
                // and keep refreshing to get a new lab instance
                // reason: frontend counts on seconds, but backend counts on minute
                setTimeout(async () => {
                  await refetchLabTime();
                  if (labPrototypeTime?.getLabTime?.timeRemaining > 1) {
                    stopPollingProcess();
                  }
                }, 1000);
              } else if (labInstance.status === 'DELETE_REQUESTED') {
                setLabStatus('Deleting');
              } else {
                const stopStartStatus =
                  labInstance.status === 'POWERING_OFF'
                    ? 'Stopping'
                    : 'Starting';
                if (labStatus !== 'Restarting') {
                  setLabStatus(stopStartStatus);
                }
                if (!pollingInProgress) {
                  // eslint-disable-next-line no-use-before-define
                  startPollingProcess();
                }
              }
            } else {
              setLabStatus('Not Started');
              stopPollingProcess();
            }
            break;
          }
          default: {
            setLabStatus('Waiting...');
            refetchListLabInstances();
            break;
          }
        }
      }
    },
    skip: !labId || !user?.username,
  });

  useEffect(() => {
    setLabStatus(null);
    if (labId) {
      refetchListLabInstances();
    }
  }, [labId, refetchListLabInstances]);

  const startPollingProcess = () => {
    startPolling(20000);
    setPollingInProgress(true);
  };

  const [startLabMutation] = useMutation(gql(startLab), {
    onError: () => {
      // retry start on error
      startPollingProcess();
      setLabStatus('Restarting');
    },
    onCompleted: () => startPollingProcess(),
  });

  const [stopLabMutation] = useMutation(gql(stopLab), {
    onCompleted: () => startPollingProcess(),
  });

  const timeRemaining = ['Ready', 'Shutdown'].includes(labStatus)
    ? labPrototypeTime?.getLabTime?.timeRemaining
    : activeTask?.modulePart?.duration;

  const milliSecondsToFinish =
    ['Ready', 'Shutdown'].includes(labStatus) && timeRemaining > 0
      ? minutesToMilliseconds(timeRemaining)
      : 0;

  const iconColor =
    theme?.palette?.type === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.54)';

  const hasLab = labId && modulePartId;

  if (
    hasLab &&
    (!labStatus ||
      labPrototypeLoading ||
      (labInstancesLoading && !['Starting', 'Stopping'].includes(labStatus)))
  ) {
    return (
      <Box mt={1} mb={1} width="100%">
        <CircularProgress size="2rem" />
      </Box>
    );
  }
  const showStartLab =
    labStatus === 'Not Started' ||
    (labStatus === 'Shutdown' && timeRemaining && timeRemaining > 1);

  const vmInstances = activeLabInstance?.vms?.items || [];

  // TODO: switch back to actual show logic after CSC
  const vmRows = vmInstances
    ?.map((vm) => {
      const vpn = VPNVMsData?.listVPNVMsByLabInstanceId?.items?.find(
        (i) => i.labInstanceVmId === vm.id,
      );
      return { ...vm, show: true, vpnDetails: vpn };
    })
    .filter((vmr) => vmr.show);

  return (
    <Box my={3}>
      <Divider />
      {hasLab && (
        <Box my={2}>
          <FdTypography variant="subtitle1">Lab Details</FdTypography>
        </Box>
      )}
      {hasLab && activeTask?.modulePartId && labStatus && (
        <Box mb={2}>
          <ContentWithLabel
            label="Current Lab Status"
            variant="subtitle2"
            content={
              <FdChip
                color={getLabStatusColor(labStatus)}
                size="medium"
                label={labStatus}
                className="ml-2"
              />
            }
          />
        </Box>
      )}

      {hasLab && labStatus === 'Deleted' && (
        <FdAlert
          alertTitle=""
          variant="info"
          customIcon={<InfoOutlined />}
          message="If your current lab status is showing as “Deleted/Error”, try closing this challenge and reopening it, and/or refreshing your page.
          In the event that your VM is inaccessible via the web console, you can use the 'Restart' button to initiate the lab's restart process.
          "
        />
      )}
      {activeTask?.modulePart &&
        [
          'Not Started',
          'Starting',
          'Building',
          'Ready',
          'Stopping',
          'Shutdown',
        ].includes(labStatus) && (
          <Box mt={3} mb={3}>
            {!['Starting', 'Stopping'].includes(labStatus) && (
              <Box mt={1} display="flex">
                <FdSkeleton loading={refetchLabTimeLoading} height="28px">
                  <Box className="flex items-center mt-1">
                    <Alarm className="mr-1" style={{ fill: iconColor }} />
                    <FdTypography color="secondary" variant="body2">
                      Lab Time Remaining
                    </FdTypography>
                    <FdTypography color="secondary" variant="body2">
                      <Box ml={1}>
                        {labStatus === 'Ready' && milliSecondsToFinish ? (
                          <Countdown
                            date={
                              new Date().getTime() +
                              // to trigger countdown, minimum 1 second
                              (milliSecondsToFinish > 1000
                                ? milliSecondsToFinish
                                : 1000)
                            }
                            onComplete={async () => {
                              if (labStatus === 'Ready' && timeRemaining > 0) {
                                await refetchLabTime();
                                await stopLabMutation({
                                  variables: {
                                    labInstanceId: activeLabInstance?.id,
                                  },
                                });
                                await refetchListLabInstances();
                              }
                            }}
                          />
                        ) : (
                          `${
                            timeRemaining
                              ? formatMinutesToHours(timeRemaining)
                              : ''
                          }`
                        )}
                      </Box>
                    </FdTypography>
                  </Box>
                </FdSkeleton>
              </Box>
            )}
            {activeTask?.modulePart?.expiry && (
              <Box className="flex items-center mt-1">
                <AvTimer className="mr-1" style={{ fill: iconColor }} />
                <FdTypography color="secondary" variant="body2">
                  Lab Expiry:
                </FdTypography>
                <Box ml={1}>
                  <FdTypography color="secondary" variant="body2">
                    {`${getDateTimeZoneFormatted(
                      activeTask?.modulePart?.expiry,
                      true,
                    )}`}
                  </FdTypography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      {labStatus !== 'Expired' && (
        <>
          <Box>
            {showStartLab && (
              <Box>
                <FdButton
                  size="large"
                  data-cy="lab-button"
                  variant="secondary"
                  onClick={() => setOpenModal(true)}
                  style={{ width: '100%' }}
                >
                  START LAB
                </FdButton>
              </Box>
            )}
            {(['Starting', 'Stopping', 'Deleting', 'Restarting'].includes(
              labStatus,
            ) ||
              (pollingInProgress && labStatus === 'Shutdown')) && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <CircularProgress size="2rem" />
              </Box>
            )}
            {labStatus === 'Ready' && (
              <Box className="flex gap-x-2">
                <Box display="flex" width="100%">
                  <FdButton
                    size="large"
                    variant="secondary"
                    style={{ width: '100%' }}
                    onClick={() => setRestartModal(true)}
                  >
                    RESTART LAB
                  </FdButton>
                  <Box my={3}>
                    <Divider />
                  </Box>
                </Box>
              </Box>
            )}
            {labStatus === 'Ready' && vmInstances.length > 0 && (
              <Box mt={3} width="100%">
                <Box my={2}>
                  <Divider />
                </Box>
                <Box my={3}>
                  <FdTypography variant="subtitle1">
                    VM Access Details
                  </FdTypography>
                </Box>
                {vmRows.map((vm, idx) => {
                  return (
                    <Box mb={3}>
                      <Box className="flex items-center my-1">
                        <FdTypography variant="subtitle2" className="pr-2">
                          {`VM ${idx + 1} Name:`}
                        </FdTypography>
                        <FdTypography variant="body2" color="secondary">
                          {vm?.name}
                        </FdTypography>
                      </Box>
                      {vm?.vpnDetails && (
                        <Box className="flex items-center mt-2">
                          <FdTypography color="secondary" variant="subtitle2">
                            {`VPN Access IP: ${
                              vm?.vpnDetails?.externalIp ?? '0.0.0.0'
                            }`}
                          </FdTypography>
                          <FdTooltip
                            alignContent="center"
                            title="Use this IP to access this VM when you are connected to the VPN provided."
                          >
                            <InfoOutlined
                              style={{
                                fill: iconColor,
                                height: '20px',
                                width: '20px',
                                marginLeft: '0.5rem',
                              }}
                              ml={2}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  vm?.vpnDetails?.externalIp ?? '0.0.0.0',
                                );
                              }}
                            />
                          </FdTooltip>
                        </Box>
                      )}
                      {vm?.hasVdi && (
                        <Box mt={1}>
                          <FdButton
                            size="medium"
                            data-cy="lab-button"
                            style={{ width: '100%' }}
                            onClick={() => {
                              window.open(
                                `/competitions/connect/${activeLabInstance?.id}/vdi/${vm?.id}`,
                              );
                            }}
                          >
                            {`CONNECT TO "${vm?.name ?? 'VM'}" VM VDI`}
                          </FdButton>
                        </Box>
                      )}
                    </Box>
                  );
                })}
                {/* <Box my={3}>
                  <FdAlert
                    message="There may be VMs within this lab which do not have VDI. As part of the challenge, you will need to find your own way to access these (if they exist)."
                    variant="info"
                  />
                </Box> */}
              </Box>
            )}
          </Box>
        </>
      )}
      <Box my={2}>
        <Divider />
      </Box>
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Start Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to start the lab?
            </FdTypography>
            <Box mt={1}>
              Your lab time will start to count down if you start the lab. You
              will not be able to access the lab after the lab time expires.
            </Box>
          </Box>
        }
        confirm="Start Lab"
        dismiss="Cancel"
        open={openModal}
        onConfirm={() => {
          if (
            activeTask &&
            activeTask?.modulePartId &&
            activeTask?.modulePart
          ) {
            setLabStatus('Starting');
            startLabMutation({
              variables: {
                labPrototypeId: labId,
                modulePartId,
                assessmentId,
              },
            });
            setOpenModal(false);
          }
        }}
        onDismiss={() => setOpenModal(false)}
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Restart this lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            Restarting this lab down will power off&nbsp;
            <b>all</b>
            &nbsp;the VMs contained within this lab. You will lose your progress
            in all VMs if you choose to confirm this action.
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={openRestartModal}
        onConfirm={() => {
          setLabStatus('Restarting');
          // stop lab
          stopLabMutation({
            variables: {
              labInstanceId: activeLabInstance?.id,
            },
          });
          setRestartModal(false);
          successToastMessage('Restart lab initiated.');
        }}
        onDismiss={() => setRestartModal(false)}
      />
    </Box>
  );
};

LabControl.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  activeTask: PropTypes.shape(
    PropTypes.shape({
      modulePart: PropTypes.shape({
        labId: PropTypes.string,
        expiry: PropTypes.string,
      }),
    }),
  ).isRequired,
  user: PropTypes.shape(PropTypes.shape({})).isRequired,
  labId: PropTypes.string.isRequired,
  modulePartId: PropTypes.string.isRequired,
};

export default LabControl;
