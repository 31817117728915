import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { useParams } from 'react-router';
import {
  gql,
  useMutation,
  useSubscription,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  BasePage,
  FdCard,
  FdTypography,
  FdButton,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import AssessmentRules from './AssessmentRules';
import {
  downloadVPNConfigureFile,
  getUserAssessment,
} from '../graphql/queries';
import { startAssessmentForUser } from '../graphql/mutations';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import { invalidateAssessmentsHomePageDataQuery } from '../queries/invalidateQueries';

const TrainingStart = () => {
  const globalSnap = useSnapshot(globalStore);
  const { assessmentId } = useParams();

  const {
    data: assessmentData,
    loading: assessmentLoading,
    refetch: refetchAssessmentTasksDataQuery,
  } = useQuery(gql(getUserAssessment), {
    variables: {
      id: assessmentId,
    },
    fetchPolicy: 'network-only',
  });

  const [downloadVPNConfig, { loading: downloadVPNConfigInProgress }] =
    useLazyQuery(gql(downloadVPNConfigureFile));

  // get the assessment selected
  const assessmentDataSelected = assessmentData?.getUserAssessment || {};

  const [startAssessment, { loading: startAssessmentInProgress }] = useMutation(
    gql(startAssessmentForUser),
    {
      refetchQueries: ['ListUserAssessments'],
      awaitRefetchQueries: true,
      onCompleted: (_data) => {
        invalidateAssessmentsHomePageDataQuery();
        singleSpa.navigateToUrl(
          `/competitions/training-tasks/${_data?.startAssessmentForUser}`,
        );
      },
      onError: () => singleSpa.navigateToUrl('/competitions/error'),
    },
  );

  useSubscription(gql(onUpdateAssessment), {
    variables: {
      id: assessmentDataSelected?.userAssessmentAssessmentId,
    },
    onData: () => {
      refetchAssessmentTasksDataQuery();
    },
  });

  useEffect(() => {
    if (assessmentDataSelected.status) {
      if (assessmentDataSelected?.status === 'STARTED') {
        singleSpa.navigateToUrl(`/competitions/training-tasks/${assessmentId}`);
        return;
      }
      // navigate to homepage if status is not valid
      if (assessmentDataSelected?.status !== 'NOT_STARTED') {
        invalidateAssessmentsHomePageDataQuery();
        singleSpa.navigateToUrl('/landing/landing-homepage');
      }
    }
  }, [assessmentDataSelected, assessmentId]);

  if (
    assessmentLoading ||
    startAssessmentInProgress ||
    downloadVPNConfigInProgress ||
    !assessmentDataSelected
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, endDateTime, preMessage },
    id: userAssessmentId,
  } = assessmentDataSelected;

  const endDate = endDateTime && getDateTimeZoneFormatted(endDateTime); // TimeZone, like AEDT

  const enterCompetition = () => {
    startAssessment({
      variables: {
        userAssessmentId,
      },
    });
  };

  return (
    <BasePage heading={name}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <AssessmentRules
            userFullName={globalSnap.name}
            preMessage={preMessage}
            assessmentData={assessmentDataSelected}
          />
        </Grid>
        <Grid item xs={3}>
          <FdCard variant="outlined" style={{ marginTop: '16px' }}>
            {endDate && (
              <Box mt={2}>
                <FdTypography variant="h4">Available until</FdTypography>
                <FdTypography variant="subtitle1">{endDate}</FdTypography>
              </Box>
            )}
            <Box mt={3}>
              <FdTypography variant="subtitle1">
                Click the &apos;Enter Training Activity&apos; button below to
                begin your training activity.
              </FdTypography>
              <Box mt={3}>
                <FdButton
                  size="medium"
                  style={{ width: '100%' }}
                  onClick={async () => {
                    if (
                      assessmentDataSelected?.assessment?.enableVPN === 'TRUE'
                    ) {
                      const orgId = assessmentDataSelected?.assessment?.orgId;
                      await downloadVPNConfig({
                        variables: {
                          userAssessmentId: assessmentDataSelected?.id,
                          assessmentId: assessmentDataSelected?.assessment?.id,
                          orgId,
                        },
                        onCompleted: () => {
                          enterCompetition();
                        },
                      });
                    } else {
                      enterCompetition();
                    }
                  }}
                >
                  Enter Training Activity
                </FdButton>
              </Box>
            </Box>
          </FdCard>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default TrainingStart;
