import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  makeStyles,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import singleSpa from 'single-spa';
import ShowMoreText from 'react-show-more-text';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import { Storage } from 'aws-amplify';
import shortid from 'shortid';
import {
  BasePage,
  FdTypography,
  FdIconsV5,
  FdMarkdownRender,
  FdLoadingSpinner,
  FdSelect,
  useQueryRecursive,
  FdAccordion,
  FdSkeleton,
  FdCard,
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
  FdExternalLink,
  FdChip,
  FdTab,
} from '@fifthdomain/fe-shared';
import TasksTable from '../components/Participant/TasksTable';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import {
  onCreateTaskActivity,
  onUpdateTaskActivity,
  onCreateNewTaskAttempt,
  onUpdateAssessment,
} from '../graphql/subscriptions';
import FileAttachment from '../components/Participant/FileAttachment';
import FlagSubmission from '../components/Participant/FlagSubmission';
import {
  listTaskActivities,
  listTaskAttemptAggregates,
  getSystemTime,
  getAssessment,
  byModulePartId,
  getModulePart,
  downloadVPNConfigureFile,
  getTeam,
  listTaskActivitiesByTeamId,
  listTaskOpenedsByUserAssessmentId,
} from '../graphql/queries';
import { listHintReveals } from '../queries/customQueries';
import {
  createTaskOpened,
  attempt,
  createTaskActivity,
  updateTaskActivity,
  deleteTaskActivity,
  startLab,
  updateTaskOpened,
  stopLab,
} from '../graphql/mutations';
import useStopAssessment from '../hooks/useStopAssessment';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import setAppMarginRightByIds from '../shared/utils/layout';
import { TASK_ACTIVITY, LEVEL_NAMES, PROFICIENCY_LEVELS } from '../constants';
import { errorToastMessage, successToastMessage } from '../shared/utils/toast';
import LevelSelector from '../components/Participant/LevelSelector';
import ProgressBarWithTitle from '../components/Participant/ProgressBar';
import { getArrayByLength } from '../shared/utils/objectUtils';
import LevelIndicator from '../components/Participant/LevelIndicator';
import ProgressGraphs from '../components/Participant/AccordionContents/ProgressGraphs';
import LabControl from '../components/Participant/LabControl';
import useGetScoreboard from '../hooks/useGetScoreboard';
import { invalidateAssessmentsHomePageDataQuery } from '../queries/invalidateQueries';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import useSubscription from '../hooks/useSubscription';
import { getDifficultyLevel } from '../shared/utils/difficultyMapping';
import TaskDrawerHeaderParticipant from '../components/Assessment/TaskDrawerHeaderParticipant';
import { sortByDateField } from '../shared/utils/dateUtils';
import ChallengeRating from '../components/Participant/ChallengeRating';
import ParticipantChallengeChat from '../components/Assessment/ParticipantChallengeChat';

const drawerWidth = 400;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
  gridMd2: {
    '@media (min-width: 960px) .competitions-MuiGrid-grid-md-2': {
      maxWidth: '14.7%',
    },
  },
  divider: {
    margin: '10px 0',
    backgroundColor: theme.palette.table.border,
  },
}));

const AssessmentTasks = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [taskActivity, setTaskActivity] = useState('');
  const [taskAttempts, setTaskAttempts] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);
  const [levelSelected, setLevelSelected] = useState(1);
  const [barGraphLegendData, setBarGraphLegendData] = useState(undefined);
  const [flagSubmitToast, setFlagSubmitToast] = useState(undefined);
  const [isJumboxStarting, setIsJumboxStarting] = useState(false);
  const [isLabReady, setIsLabReady] = useState(false);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const jumpboxSharedModulepartId = '4ad5a685-8a58-460b-ac9b-45b522f37818';
  const [solutionModal, setSolutionModal] = useState(false);
  const [isVPNdownload, setIsVPNdownload] = useState(false);
  const [isVPNCopying, setIsVPNCopying] = useState(false);
  const [lastActiveTaskOpened, setLastActiveTaskOpened] = useState(undefined);
  const [activeTaskOpenedId, setActiveTaskOpenedId] = useState('');
  const [restartingJumpBox, setRestartingJumpBox] = useState(false);

  const globalSnap = useSnapshot(globalStore);
  const { assessmentId } = useParams();
  const classes = useStyles();
  const { Close, EmojiObjects, Download, ContentCopy } = FdIconsV5;

  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({
      userId: globalSnap.userId,
    });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  const assessmentDataFiltered =
    assessmentTasksData?.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  const { loading: getAssessmentLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });

  const [setTaskOpen] = useMutation(gql(createTaskOpened), {
    onCompleted: (data) => {
      setActiveTaskOpenedId(data.createTaskOpened?.id);
    },
  });
  const [updateTaskOpenedMutation, { loading: updateTaskOpenedLoading }] =
    useMutation(gql(updateTaskOpened), {
      onCompleted: (data) => {
        setActiveTaskOpenedId(data.updateTaskOpened?.id);
      },
    });
  const taskOpenedFilter = assessmentDataFiltered?.teamId
    ? { teamId: { eq: assessmentDataFiltered?.teamId } }
    : {
        taskOpenedUserAssessmentId: {
          eq: assessmentId,
        },
      };

  const {
    data: taskOpenedData,
    loading: taskOpenedLoading,
    refetch: refetchTaskOpened,
  } = useQueryRecursive(gql(listTaskOpenedsByUserAssessmentId), {
    variables: {
      taskOpenedUserAssessmentId:
        assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 1000,
      filter: taskOpenedFilter,
    },
    skip: !assessmentId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const recentTaskOpenedData = [
        ...(_data?.listTaskOpenedsByAssessmentId?.items?.filter(
          (ta) => ta?.taskOpenedTaskId === activeTask?.id,
        ) || []),
      ]
        ?.filter((ot) => ot.id !== activeTaskOpenedId)
        ?.sort(sortByDateField('updatedAt', 'desc'));

      const recentTaskOpened = recentTaskOpenedData?.[0];
      setLastActiveTaskOpened(recentTaskOpened);

      const taskOpenedParams = {
        taskOpenedTaskId: activeTask?.id,
        teamId: assessmentDataFiltered?.teamId || undefined,
        taskOpenedUserAssessmentId: assessmentId,
        userId: globalSnap?.userId,
        assessmentId: assessmentDataFiltered?.assessment?.id,
        startedSolving: recentTaskOpened
          ? recentTaskOpened?.startedSolving
          : false,
        startedSolvingAt: recentTaskOpened?.startedSolvingAt
          ? recentTaskOpened?.startedSolvingAt
          : undefined,
      };

      if (
        activeTask?.id &&
        activeTask?.status !== 'Completed' &&
        recentTaskOpenedData?.length === 0
      ) {
        setTaskOpen({
          variables: {
            input: taskOpenedParams,
          },
        });
      }
    },
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.value?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  // clear margins on exit
  useEffect(() => {
    return () => setAppMarginRightByIds(['topnav', 'competitions'], '0');
  }, []);

  const assessmentStatus = assessmentDataFiltered?.status;
  const assessmentFinishedOn = assessmentDataFiltered?.finishedOn;
  const enableJumpbox = assessmentDataFiltered?.assessment?.enableJumpbox;
  const enableVPN = assessmentDataFiltered?.assessment?.enableVPN;

  const { data: sharedModulePartData } = useQuery(gql(getModulePart), {
    variables: {
      id: jumpboxSharedModulepartId,
    },
  });

  // redirect to home page if not started
  useEffect(() => {
    if (
      (!assessmentTasksLoading &&
        assessmentStatus &&
        assessmentStatus !== 'STARTED') ||
      assessmentFinishedOn
    ) {
      invalidateAssessmentsHomePageDataQuery();
      singleSpa.navigateToUrl('/landing/landing-homepage');
    }
  }, [assessmentTasksLoading, assessmentStatus, assessmentFinishedOn]);

  const showFlagSubmitToast = () => {
    if (flagSubmitToast) {
      const toast =
        flagSubmitToast?.type === 'success'
          ? successToastMessage
          : errorToastMessage;
      toast(flagSubmitToast?.message);
      setFlagSubmitToast(undefined);
    }
  };

  const onTaskAttemptsComplete = (data) => {
    if (activeTask?.id) {
      let tasksData =
        data?.listTaskAttemptAggregates?.items?.filter(
          (t) => t.taskId === activeTask?.id,
        ) || [];

      if (assessmentDataFiltered?.assessment?.teamBased) {
        tasksData = tasksData?.filter(
          (teamTaskAttempt) =>
            teamTaskAttempt.teamId === assessmentDataFiltered?.teamId,
        );
      }
      const attempts = tasksData[0]?.attempts || 0;
      const completed = tasksData[0]?.status === 'COMPLETED';
      let status = attempts > 0 ? 'Attempted' : 'Incomplete';
      if (completed) {
        status = 'Completed';
      }
      setTaskAttempts(attempts);
      setTaskStatus(status);
      showFlagSubmitToast();

      // for TRAINING tasks submit stop-assessment mutation if all tasks are complete
      if (
        assessmentDataFiltered?.assessment?.participantEventType === 'TRAINING'
      ) {
        const completedTasks =
          data?.listTaskAttemptAggregates?.items?.filter(
            (t) => t.status === 'COMPLETED',
          )?.length || 0;
        // all tasks are completed then stop the user assessment
        if (
          completedTasks ===
          assessmentDataFiltered?.assessment?.tasks?.items?.length
        ) {
          stopAssessment({
            variables: {
              userAssessmentId: assessmentDataFiltered?.id,
            },
          });
        }
      }
    }
  };

  const {
    data: listTaskAttemptsScoreboardData,
    loading: listTaskAttemptsScoreboardLoading,
    refetch: refetchScoreboard,
  } = useGetScoreboard({
    assessmentId: assessmentDataFiltered?.assessment?.id,
    teamBased: assessmentDataFiltered?.assessment?.teamBased,
    ...(assessmentDataFiltered?.assessment?.teamBased
      ? { teamId: assessmentDataFiltered?.teamId }
      : { userId: globalSnap?.userId }),
  });

  const { data: listHintRevealsData, loading: listHintRevealsLoading } =
    useQueryRecursive(gql(listHintReveals), {
      variables: {
        filter: {
          taskAssessmentId: { eq: activeTask?.taskAssessmentId },
        },
        limit: 1000,
      },
      skip: !activeTask?.taskAssessmentId || !activeTask?.hints?.items?.length,
    });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsUserData,
    loading: tasksAttemptsLoading,
    refetch: refetchTasksAttempts,
  } = useQueryRecursive(gql(listTaskAttemptAggregates), {
    variables: {
      filter: {
        userAssessmentId: { eq: assessmentDataFiltered?.id },
      },
      limit: 1000,
    },
    onCompleted: onTaskAttemptsComplete,
    skip:
      !assessmentDataFiltered?.id ||
      assessmentDataFiltered?.assessment?.teamBased,
  });

  // get activity for the user
  const {
    data: tasksActivitiesData,
    refetch: refetchTaskActivitiesUser,
    loading: taskActivitiesUserLoading,
  } = useQueryRecursive(gql(listTaskActivities), {
    variables: {
      filter: {
        userId: { eq: globalSnap.userId },
        assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
        teamId: { eq: assessmentDataFiltered?.teamId },
      },
      limit: 1000,
    },
    skip:
      !assessmentDataFiltered?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  // get activity for all users
  const {
    data: tasksActivitiesAllUsersData,
    refetch: refetchActivityAllUsers,
    loading: tasksActivitiesAllUsersLoading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQueryRecursive(gql(listTaskActivitiesByTeamId), {
    variables: {
      assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
      teamId: assessmentDataFiltered?.teamId,
      limit: 1000,
    },
    skip:
      !assessmentDataFiltered?.assessment?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createTaskActivityMutation] = useMutation(gql(createTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateTaskActivityMutation] = useMutation(gql(updateTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteTaskActivityMutation] = useMutation(gql(deleteTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    refetch: refetchAttemptsTeamBased,
    loading: taskAttemptsAssessmentTeamBasedLoading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQueryRecursive(gql(listTaskAttemptAggregates), {
    variables: {
      filter: {
        assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
        teamId: { eq: assessmentDataFiltered?.teamId },
      },
      limit: 1000,
    },
    onCompleted: onTaskAttemptsComplete,
    skip:
      !assessmentDataFiltered?.assessment?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  const refetchAttempts = () =>
    assessmentDataFiltered?.assessment?.teamBased
      ? refetchAttemptsTeamBased()
      : refetchTasksAttempts();

  const { data: teamMembersData, loading: teamMembersDataLoading } = useQuery(
    gql(getTeam),
    {
      variables: {
        id: assessmentDataFiltered?.teamId,
      },
      fetchPolicy: 'cache-first',
      skip: !assessmentDataFiltered || !assessmentDataFiltered?.teamId,
    },
  );

  const refreshActivity = (_result, action) => {
    const { assessmentId: activityAssessmentId, teamId: activityTeamId } =
      _result?.value?.data?.[`on${action}TaskActivity`] || {
        activityAssessmentId: undefined,
        activityTeamId: undefined,
      };
    if (
      activityAssessmentId ===
        assessmentDataFiltered?.userAssessmentAssessmentId &&
      activityTeamId === assessmentDataFiltered?.teamId
    ) {
      refetchActivityAllUsers();
    }
  };

  useSubscription({
    query: gql(onCreateTaskActivity),
    onData: (_data) => {
      refreshActivity(_data, 'Create');
    },
  });

  useSubscription({
    query: gql(onUpdateTaskActivity),
    onData: (_data) => {
      refreshActivity(_data, 'Update');
    },
  });

  useSubscription({
    query: gql(onCreateNewTaskAttempt),
    variables: {
      teamId: assessmentDataFiltered?.teamId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onData: (_result) => {
      const { success, user: subUser } = _result?.value?.data
        ?.onCreateNewTaskAttempt || { success: false, user: undefined };
      // if attempts query in-flight then ignore
      if (assessmentDataFiltered?.assessment?.teamBased) {
        if (!taskAttemptsAssessmentTeamBasedLoading && !tasksAttemptsLoading) {
          refetchAttempts();
        }
        if (success && globalSnap.userId !== subUser.id) {
          successToastMessage(`${subUser?.alias} solved a challenge!`);
        }
        if (success) {
          refetchScoreboard();
        }
      }
    },
  });

  const {
    data: labInstanceData,
    loading: labInstanceLoading,
    startPolling,
    stopPolling,
    refetch: refetchListLabInstances,
  } = useQueryRecursive(gql(byModulePartId), {
    variables: {
      userId: { eq: globalSnap.userId },
      modulePartId: jumpboxSharedModulepartId,
      filter: {
        or: [{ status: { eq: 'READY' } }, { status: { eq: 'POWERING_ON' } }],
        assessmentId: { eq: assessmentId },
      },
    },
    staleTime: { seconds: 0 },
    skip: enableJumpbox !== 'TRUE',
    onCompleted: (data) => {
      // start polling lab instance until its READY
      const labInstanceStatus = data?.byModulePartId?.items?.[0]?.status;
      if (labInstanceStatus && labInstanceStatus === 'READY') {
        setIsLabReady(true);
        setIsJumboxStarting(false);
        stopPolling();
        return;
      }
      // start while restart
      if (restartingJumpBox) {
        // eslint-disable-next-line no-use-before-define
        startLabMutation({
          variables: {
            labPrototypeId: sharedModulePartData?.getModulePart?.labId,
            modulePartId: sharedModulePartData?.getModulePart?.id,
            assessmentId,
          },
        });
      }
      setIsLabReady(false);

      if (data?.byModulePartId?.items.length > 0) {
        startPolling(5000);
      }
    },
  });

  const [startLabMutation, { loading: startLabLoading }] = useMutation(
    gql(startLab),
    {
      onError: () => {
        // retry start on error
        refetchListLabInstances();
        setRestartingJumpBox(true);
      },
      onCompleted: () => {
        refetchListLabInstances();
        setRestartingJumpBox(false);
      },
    },
  );

  const [stopLabMutation, { loading: stopLabLoading }] = useMutation(
    gql(stopLab),
    {
      onCompleted: () => {
        setTimeout(() => {
          refetchListLabInstances();
        }, 5000);
      },
    },
  );

  const [downloadVPNConfig] = useLazyQuery(gql(downloadVPNConfigureFile));

  const validationSchema = Yup.object().shape({
    flag: Yup.string().required('Please enter a flag to submit'),
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { control, reset, handleSubmit, getValues } = useForm({
    defaultValues: { flag: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    assessment: {
      name,
      tasks,
      multiLevel,
      guided,
      level: levelCount,
      startDateTime,
    },
    id: userAssessmentId,
  } = assessmentDataFiltered || { assessment: {}, id: {} };

  const updatedStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in ended state or not started state
  if (
    !assessmentTasksLoading &&
    assessmentDataFiltered &&
    ['ENDED', 'NOT_STARTED'].includes(updatedStatus)
  ) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const teamMembers =
    teamMembersData?.getTeam?.members?.items?.map(
      (member) => member.User?.id,
    ) || [];

  const allUserActivity =
    tasksActivitiesAllUsersData?.listTaskActivitiesByTeamId?.items
      .filter((la) => teamMembers?.includes(la.userId))
      .map((a) => ({
        activity: a.activity,
        name: teamMembersData?.getTeam?.members?.items?.find(
          (gm) => gm.User?.id === a.userId,
        )?.User?.alias,
        taskId: a.taskId,
      })) || [];

  const tasksAttemptsData =
    (assessmentDataFiltered?.assessment?.teamBased
      ? tasksAttemptsAssessmentData?.listTaskAttemptAggregates?.items?.filter(
          (ta) => teamMembers?.includes(ta.userId),
        )
      : tasksAttemptsUserData?.listTaskAttemptAggregates?.items) || [];

  const tasksData = multiLevel
    ? assessmentDataFiltered?.assessment?.levels?.items
        ?.map((l) => ({
          ...l,
          tasks: {
            items: l.tasks?.items.map((t) => ({ ...t, levelId: l.id })),
          },
        }))
        ?.find((_l) => _l.levelNumber === levelSelected)?.tasks || []
    : tasks;

  const tableRows =
    tasksData?.items?.map((i) => {
      const allTaskAttempts =
        tasksAttemptsData?.find((ta) => ta.taskId === i.task?.id) || [];
      const attempts = allTaskAttempts?.attempts || 0;
      const completed = allTaskAttempts?.status === 'COMPLETED';
      const taskOpened =
        [
          ...(taskOpenedData?.listTaskOpenedsByAssessmentId?.items?.sort(
            sortByDateField('updatedAt', 'desc'),
          ) || []),
        ]?.filter((ta) => ta.taskOpenedTaskId === i.task.id) || [];
      const taskStarted = taskOpened?.[0]?.startedSolving;
      let status =
        attempts > 0
          ? 'Attempted'
          : taskStarted
          ? 'Started'
          : taskOpened?.length > 0
          ? 'Opened'
          : 'Incomplete';

      if (completed) {
        status = 'Completed';
      }

      return {
        ...i.task,
        levelId: i.levelId,
        status,
        attempts,
        activities: allUserActivity.filter((ua) => ua.taskId === i.task.id),
        difficultyInteger: i.task?.difficulty,
        difficulty:
          i.task?.difficulty > 5
            ? getDifficultyLevel(i.task?.difficulty)
            : PROFICIENCY_LEVELS[i.task?.difficulty],
        specialty: i.task?.specialty?.name,
        skills:
          i?.task?.skills?.items?.map((s) => ({
            name: s?.skill?.name,
            alias: s?.skill?.alias,
          })) || [],
        creator: i.task?.org?.name,
        modulePartId: i.modulePartId,
        modulePart: i.modulePart,
        taskAssessmentId: i?.id,
        labId: i.task?.labId,
      };
    }) || [];

  const scoreboardData = assessmentDataFiltered?.assessment?.teamBased
    ? listTaskAttemptsScoreboardData?.listScoreboardsByTeamId?.items?.[0]
    : listTaskAttemptsScoreboardData?.listScoreboardsByUserId?.items?.[0];

  const tasksCompleted = scoreboardData?.flags || 0;
  const firstBloods = scoreboardData?.firstBloods || 0;
  const totalPoints = scoreboardData?.points || 0;

  const handleOpenDrawer = (drawerState) => {
    const mainPageIds = ['topnav', 'competitions'];
    setOpenDrawer(drawerState);
    const marginRightBy = drawerState ? '400px' : '0';
    setAppMarginRightByIds(mainPageIds, marginRightBy);
  };

  const getCurrentActivity = (_taskId) =>
    tasksActivitiesData?.listTaskActivities?.items.find(
      (ta) =>
        ta.taskId === _taskId &&
        ta.userId === globalSnap.userId &&
        ta.assessmentId === assessmentDataFiltered?.userAssessmentAssessmentId,
    );

  const getCurrentActivityLabel = (_taskId) => {
    const activity = getCurrentActivity(_taskId)?.activity;
    return TASK_ACTIVITY.find((t) => t.value === activity)?.desc || '';
  };
  const hasActiveTaskStartedSolving = lastActiveTaskOpened?.startedSolving;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [submitAttempt, { loading: flagSubmissionLoading }] = useMutation(
    gql(attempt),
    {
      onCompleted: (_data) => {
        refetchAttempts();
        if (_data.attempt.success) {
          setFlagSubmitToast({
            type: 'success',
            message: 'Success! You have entered the correct flag.',
          });
        } else {
          setFlagSubmitToast({
            type: 'error',
            message: 'You did not get the right flag.',
          });
        }
        refetchScoreboard(); // refetch scoreboard on every attempt
      },
    },
  );

  if (
    assessmentTasksLoading ||
    stopAssessmentInProgress ||
    teamMembersDataLoading ||
    serverTimeLoading ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const onSubmitFlag = (flag) => {
    submitAttempt({
      variables: {
        answer: flag,
        taskId: activeTask?.id,
        levelId: activeTask?.levelId,
        userAssessmentId,
      },
    });
    reset();
  };

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="120px">
        <FdTypography variant="subtitle1">{label}</FdTypography>
      </Box>
      {content}
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
  };

  const onActivityChange = (value) => {
    setTaskActivity(value);
    const currentActivityId = getCurrentActivity(activeTask.id)?.id;
    const activity = TASK_ACTIVITY.find((t) => t.desc === value)?.value;
    const isNotWorking = activity === 'NOT_WORKING';
    // just to create an illusion that the selection worked.
    setTimeout(() => {
      if (isNotWorking) {
        setTaskActivity('');
      }
    }, 2000);

    // if no activity present create else update
    if (currentActivityId) {
      if (isNotWorking) {
        deleteTaskActivityMutation({
          variables: {
            input: {
              id: currentActivityId,
            },
          },
        });
        return;
      }
      updateTaskActivityMutation({
        variables: {
          input: {
            id: currentActivityId,
            activity,
          },
        },
      });
    } else {
      createTaskActivityMutation({
        variables: {
          input: {
            userId: globalSnap.userId,
            assessmentId: assessmentDataFiltered?.assessment?.id,
            taskId: activeTask.id,
            teamId: assessmentDataFiltered?.teamId,
            activity,
          },
        },
      });
    }
  };
  const teamBased = assessmentDataFiltered?.assessment?.teamBased || false;

  const ActivitySelector = ({ activity }) => (
    <Box mt={1} mb={2}>
      <FdSelect
        id="taskActivity"
        defaultSelected={activity}
        options={TASK_ACTIVITY.map((t) => t.desc)}
        onChange={onActivityChange}
        placeholder="Select Activity"
        data-cy="task-activity"
      />
    </Box>
  );

  ActivitySelector.propTypes = {
    activity: PropTypes.string.isRequired,
  };

  const getPercentageLevelCompleted = (_level) => {
    const _tasks =
      assessmentDataFiltered?.assessment?.levels?.items?.find(
        (_l) => _l.levelNumber === _level,
      )?.tasks || [];
    const _tasksWithStatus =
      _tasks?.items?.map((i) => {
        const allTaskAttempts =
          tasksAttemptsData?.filter((ta) => ta.taskId === i.task?.id) || [];
        return {
          completed: allTaskAttempts.some((ta) => ta.status === 'COMPLETED'),
        };
      }) || [];
    return Math.round(
      (_tasksWithStatus?.filter((t) => t.completed)?.length /
        _tasks?.items?.length) *
        100,
    );
  };

  const allLevels = getArrayByLength(levelCount || 0, (_, i) => i + 1);
  const allLevelDetails = allLevels.map((l, index) => ({
    level: l,
    levelName: LEVEL_NAMES[index] || `Level ${l}`,
    levelTitle: 'Mission',
    selected: levelSelected === l,
    percentageComplete: getPercentageLevelCompleted(l),
  }));

  const selectLevelData = {
    levelName: LEVEL_NAMES[levelSelected - 1] || `Level ${levelSelected}`,
    levelTitle: 'Mission',
    attempted: tableRows.filter((t) => t.attempts)?.length || 0,
    solved: tableRows.filter((t) => t.status === 'Completed')?.length || 0,
    pointScored:
      tableRows?.reduce(
        (acc, p) => acc + (p.status === 'Completed' ? p.recommendedPoints : 0),
        0,
      ) || 0,
    totalTasks: tableRows?.length,
    totalPoints:
      tableRows?.reduce((acc, p) => acc + p.recommendedPoints, 0) || 0,
  };

  const ProgressSpinner = () => (
    <FdSkeleton loading width="128px" height="20px" />
  );
  const labInstance = labInstanceData?.byModulePartId?.items?.[0];

  const CardDetails = () => (
    <Box minHeight={645}>
      {teamBased && taskStatus !== 'Completed' && (
        <ActivitySelector activity={taskActivity} />
      )}
      <Box mt={2} mb={2} className={classes.description}>
        <FdTypography variant="subtitle1">Description</FdTypography>
        <FdTypography variant="body1" color="secondary">
          <ShowMoreText
            lines={8}
            more="See more"
            less="See less"
            anchorClass="see-more"
          >
            <FdMarkdownRender markdown={activeTask?.description} />
          </ShowMoreText>
        </FdTypography>
      </Box>
      {activeTask?.skills?.length > 0 && (
        <Box mb={2}>
          <FdTypography variant="subtitle1" data-cy="skills-title">
            Skills
          </FdTypography>
          <Box ml={4}>
            <FdTypography variant="body1" color="secondary">
              <ul
                key={shortid.generate()}
                data-cy="skills"
                style={{ listStyleType: 'disc' }}
              >
                {activeTask?.skills?.map((skill) => (
                  <li>{`${skill.name} (${skill.alias})`}</li>
                ))}
              </ul>
            </FdTypography>
          </Box>
        </Box>
      )}
      {taskOpenedLoading || updateTaskOpenedLoading ? (
        <FdLoadingSpinner />
      ) : hasActiveTaskStartedSolving ? (
        <>
          {guided && activeTask?.solutionVideo && (
            <Box mb={2} className={classes.description}>
              <FdButton
                variant="primary"
                onClick={() => {
                  setSolutionModal(true);
                }}
              >
                View Solution Walkthrough
              </FdButton>
            </Box>
          )}
          <LabControl
            assessmentId={assessmentId}
            activeTask={activeTask}
            user={globalSnap.user}
            labId={activeTask?.labId}
            modulePartId={activeTask?.modulePartId}
          />
          <Box>
            {activeTask?.files?.items?.length !== 0 && (
              <Box mb={2}>
                <FdTypography variant="subtitle1">Attachments</FdTypography>
                {activeTask?.files.items
                  .filter((file) =>
                    [
                      'application/x-zip-compressed',
                      'application/zip',
                    ].includes(file.type),
                  )
                  .map((f) => (
                    <FileAttachment
                      id={f.id}
                      key={f.id}
                      name={f.name}
                      url={f.url}
                    />
                  ))}
              </Box>
            )}
            {listHintRevealsLoading ? (
              <FdLoadingSpinner />
            ) : (
              listHintRevealsData?.listHintReveals?.items?.length > 0 && (
                <Box mb={2}>
                  <FdTypography variant="subtitle1">Hints</FdTypography>
                  <FdTypography variant="body1" color="secondary">
                    {listHintRevealsData?.listHintReveals?.items?.map(
                      (item) => (
                        <Box key={item?.id}>
                          <Box display="flex" flexDirection="row" mt={1.5}>
                            <EmojiObjects />
                            <Box ml={1.5}>
                              {
                                activeTask?.hints?.items?.find(
                                  (_item) => _item?.id === item?.hintId,
                                )?.text
                              }
                            </Box>
                          </Box>
                        </Box>
                      ),
                    )}
                  </FdTypography>
                </Box>
              )
            )}
          </Box>
          {taskStatus !== 'Completed' && (
            <FlagSubmission
              onClickSubmit={() => onSubmitFlag(getValues('flag'))}
              loading={flagSubmissionLoading || flagSubmitToast}
              handleSubmit={handleSubmit}
              control={control}
              Controller={Controller}
            />
          )}
          <ChallengeRating
            userId={globalSnap.userId}
            assessmentId={assessmentDataFiltered?.assessment?.id}
            taskId={activeTask?.id}
            userAssessmentId={assessmentId}
          />
        </>
      ) : (
        <>
          <FdTypography color="secondary">
            Click below to see detailed challenge view including attachments,
            virtual environments, and hints to start solving the challenge.
          </FdTypography>
          <FdButton
            variant="secondary"
            onClick={() => {
              updateTaskOpenedMutation({
                variables: {
                  input: {
                    id: activeTaskOpenedId || lastActiveTaskOpened?.id,
                    startedSolving: true,
                    startedSolvingAt: new Date().toISOString(),
                  },
                },
                onCompleted: (_data) => {
                  setLastActiveTaskOpened(_data.updateTaskOpened);
                  setActiveTaskOpenedId('');
                },
              });
            }}
            style={{ marginTop: '16px' }}
          >
            Start Solving
          </FdButton>
        </>
      )}
    </Box>
  );

  return (
    <BasePage
      heading={name}
      data-cy="assessment-tasks-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel={name}
      renderBreadCrumbAsButton
    >
      <Grid container>
        <Grid item xs>
          {/* <FilteredList /> */}
          {multiLevel ? (
            <Box mb={6}>
              <Box height="80px" width="100%">
                <AssessmentSummary
                  assessment={{
                    tasksCompleted,
                    endDateTime,
                    firstBloods,
                    totalPoints,
                    teamBased,
                    teamId: assessmentDataFiltered?.teamId,
                  }}
                  onFinish={() =>
                    stopAssessment({
                      variables: {
                        userAssessmentId,
                      },
                    })
                  }
                  loading={
                    tasksAttemptsLoading ||
                    taskAttemptsAssessmentTeamBasedLoading ||
                    listTaskAttemptsScoreboardLoading
                  }
                />
              </Box>
            </Box>
          ) : (
            <FdAccordion
              showToggleButton={false}
              summary={() => (
                <Box height="80px" width="100%" sx={{ marginBottom: '-10px' }}>
                  <AssessmentSummary
                    assessment={{
                      tasksCompleted,
                      endDateTime,
                      firstBloods,
                      totalPoints,
                      teamBased,
                      teamId: assessmentDataFiltered?.teamId,
                      orgId: assessmentDataFiltered?.user?.orgId,
                      userId: globalSnap.userId,
                    }}
                    onFinish={() =>
                      stopAssessment({
                        variables: {
                          userAssessmentId,
                        },
                      })
                    }
                    noBorderBox
                    loading={
                      tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading ||
                      listTaskAttemptsScoreboardLoading
                    }
                  />
                </Box>
              )}
              content={() => (
                <Box>
                  <ProgressGraphs
                    data={tableRows}
                    barGraphLegendData={barGraphLegendData}
                    setBarGraphLegendData={setBarGraphLegendData}
                  />
                </Box>
              )}
              endAdornment
            />
          )}
          {(enableJumpbox === 'TRUE' || enableVPN === 'TRUE') && (
            <Box display="flex" justifyContent="space-between">
              {enableVPN === 'TRUE' && (
                <FdCard
                  variant="outlined"
                  style={{
                    width:
                      enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                        ? '49%'
                        : '100%',
                  }}
                >
                  <Box>
                    <Box mb={2}>
                      <FdTypography variant="subtitle1">
                        VPN Configuration
                      </FdTypography>
                    </Box>
                    <Box mb={2} className="flex items-center">
                      <Box>
                        <FdButton
                          startIcon={<Download />}
                          disabled={!!isVPNdownload}
                          onClick={async () => {
                            setIsVPNdownload(true);
                            const orgId = assessmentDataFiltered?.user?.orgId;

                            downloadVPNConfig({
                              variables: {
                                userAssessmentId: assessmentDataFiltered?.id,
                                assessmentId:
                                  assessmentDataFiltered?.assessment?.id,
                                orgId,
                              },
                              onCompleted: (data) => {
                                const text = atob(
                                  data.downloadVPNConfigureFile,
                                );
                                const blob = new Blob([text], {
                                  type: 'text/plain',
                                });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `user-config-${assessmentDataFiltered?.assessment?.id}.ovpn`;
                                a.click();
                                URL.revokeObjectURL(url);
                                setIsVPNdownload(false);
                              },
                            });
                          }}
                        >
                          {isVPNdownload
                            ? 'Waiting...'
                            : 'Download VPN Configuration File'}
                        </FdButton>
                      </Box>
                      <Box ml={1}>
                        <FdTypography variant="body1">or</FdTypography>
                      </Box>
                      <Box ml={1}>
                        <FdButton
                          startIcon={<ContentCopy />}
                          disabled={!!isVPNCopying}
                          onClick={async () => {
                            setIsVPNCopying(true);
                            const orgId = assessmentDataFiltered?.user?.orgId;
                            const key = `user-config-${assessmentDataFiltered?.assessment?.id}-fd.ovpn`;
                            const currentDate = new Date();
                            const expiryDate = new Date(currentDate);
                            expiryDate.setHours(currentDate.getHours() + 24);

                            try {
                              await Storage.get(key, {
                                level: 'private',
                                contentType: 'text/plain',
                                cacheControl: 'no-cache',
                                validateObjectExistence: true,
                              });

                              const userConfigLink = await Storage.get(key, {
                                level: 'private',
                                contentType: 'text/plain',
                                cacheControl: 'no-cache',
                              });

                              navigator.clipboard
                                .writeText(userConfigLink)
                                .then(() => {
                                  successToastMessage(
                                    'VPN Configuration link copied successfully.',
                                  );
                                });

                              setIsVPNCopying(false);
                            } catch (error) {
                              downloadVPNConfig({
                                variables: {
                                  userAssessmentId: assessmentDataFiltered?.id,
                                  assessmentId:
                                    assessmentDataFiltered?.assessment?.id,
                                  orgId,
                                },
                                onCompleted: async (data) => {
                                  const text = atob(
                                    data.downloadVPNConfigureFile,
                                  );

                                  const expiryDate48 = new Date(currentDate);
                                  expiryDate48.setHours(
                                    currentDate.getHours() + 48,
                                  );

                                  await Storage.put(key, text, {
                                    level: 'private',
                                    contentType: 'text/plain',
                                    cacheControl: 'no-cache',
                                    expires: expiryDate48,
                                  });

                                  const userConfigLink = await Storage.get(
                                    key,
                                    {
                                      level: 'private',
                                      cacheControl: 'no-cache',
                                    },
                                  );

                                  navigator.clipboard
                                    .writeText(userConfigLink)
                                    .then(() => {
                                      successToastMessage(
                                        'VPN Configuration link copied successfully.',
                                      );
                                    });

                                  setIsVPNCopying(false);
                                },
                              });
                            }
                          }}
                        >
                          {isVPNCopying
                            ? 'Waiting...'
                            : 'Copy VPN Configuration Link'}
                        </FdButton>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <FdTypography variant="body2">
                        You must use the provided VPN file on your workstation
                        or jump box to securely connect to the lab virtual
                        machines(VMs) in each challenge. You can either download
                        the VPN configuration file directly or copy the download
                        URL to into the jumpbox or elsewhere.
                        <FdExternalLink
                          href="https://au.intercom.help/fifth-domain/en/articles/2396-using-vpn-in-a-competition"
                          noUnderline
                        >
                          Click here
                        </FdExternalLink>
                        to learn more about using VPN configuration file.
                      </FdTypography>
                    </Box>
                  </Box>
                </FdCard>
              )}

              {enableJumpbox === 'TRUE' && (
                <FdCard
                  variant="outlined"
                  style={{
                    width:
                      enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                        ? '50%'
                        : '100%',
                  }}
                >
                  <Box>
                    <Box mb={2}>
                      <FdTypography variant="subtitle1">Jump Box</FdTypography>
                    </Box>
                    <Box className="flex gap-x-3 mb-4">
                      <FdSkeleton
                        loading={
                          labInstanceLoading ||
                          isJumboxStarting ||
                          startLabLoading ||
                          stopLabLoading
                        }
                        height="32px"
                        width="198px"
                      >
                        <FdButton
                          style={
                            isLabReady
                              ? {
                                  backgroundColor: '#228b22',
                                  color: '#FFFFFF',
                                  borderColor: '#228b22',
                                }
                              : {}
                          }
                          disabled={
                            isJumboxStarting ||
                            startLabLoading ||
                            stopLabLoading
                          }
                          onClick={() => {
                            if (isLabReady) {
                              const vmId = labInstance?.vms?.items?.[0]?.id;
                              window.open(
                                `/competitions/connect/${labInstance?.id}/vdi/${vmId}`,
                              );
                              refetchListLabInstances();
                            } else if (!startLabLoading) {
                              setIsJumboxStarting(true);
                              startLabMutation({
                                variables: {
                                  labPrototypeId:
                                    sharedModulePartData?.getModulePart?.labId,
                                  modulePartId:
                                    sharedModulePartData?.getModulePart?.id,
                                  assessmentId,
                                },
                              });
                            }
                          }}
                        >
                          {!isLabReady || startLabLoading ? (
                            <Box display="flex" alignItems="center">
                              <Box mr={2}>Start Jump Box</Box>
                              {isJumboxStarting && (
                                <CircularProgress
                                  size={25}
                                  style={{ color: '#fff' }}
                                />
                              )}
                            </Box>
                          ) : (
                            'Connect to Jump Box'
                          )}
                        </FdButton>
                      </FdSkeleton>
                      {['POWERING_ON', 'POWERING_OFF']?.includes(
                        labInstance?.status,
                      ) && (
                        <FdChip
                          color="warning"
                          size="medium"
                          label={
                            labInstance?.status === 'POWERING_ON'
                              ? 'Starting'
                              : 'Stopping'
                          }
                          className="ml-2"
                        />
                      )}
                      {isLabReady && (
                        <FdButton
                          variant="secondary"
                          disabled={isJumboxStarting || restartingJumpBox}
                          onClick={() => {
                            setIsJumboxStarting(true);
                            setRestartingJumpBox(true);
                            stopLabMutation({
                              variables: {
                                labInstanceId: labInstance?.id,
                              },
                            });
                          }}
                        >
                          Restart Jump box
                        </FdButton>
                      )}
                    </Box>
                    <Box mb={2}>
                      <FdTypography variant="body2">
                        To securely access challenges, click &apos;Start Jump
                        Box&apos; to initiate the process. After a short setup,
                        connect by clicking &apos;Connect Jump Box&apos;. You
                        can find the Jump Box credentials on the Competition
                        Overview page in the left navigation menu.
                        <FdExternalLink
                          href="https://au.intercom.help/fifth-domain/en/articles/2346-using-jump-box-in-your-competition"
                          noUnderline
                        >
                          Click here
                        </FdExternalLink>
                        learn more about Jump Box.
                      </FdTypography>
                    </Box>
                  </Box>
                </FdCard>
              )}
            </Box>
          )}
          <Box className="flex w-full" mt={1}>
            {multiLevel && (
              <Box width="14%" style={{ marginRight: '1%' }}>
                <LevelSelector
                  levels={allLevelDetails}
                  onSelect={(_level) => {
                    setLevelSelected(_level);
                    setBarGraphLegendData('');
                    handleOpenDrawer(false);
                  }}
                />
              </Box>
            )}
            <Box width={multiLevel ? '85%' : '100%'}>
              {multiLevel && (
                <FdAccordion
                  summary={() => (
                    <Box
                      display="flex"
                      height="80px"
                      alignItems="center"
                      justifyContent="space-around"
                      width="100%"
                    >
                      <Box px={2} width="80px">
                        <LevelIndicator
                          level={selectLevelData}
                          fontVariant="h4"
                        />
                      </Box>
                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                        flexItem
                      />
                      {tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading ? (
                        <ProgressSpinner />
                      ) : (
                        <ProgressBarWithTitle
                          color="blue"
                          title="Total Challenges attempted"
                          value={Math.round(
                            (selectLevelData?.attempted /
                              selectLevelData?.totalTasks) *
                              100,
                          )}
                          caption={`${selectLevelData?.attempted}/${selectLevelData?.totalTasks}`}
                        />
                      )}
                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                        flexItem
                      />
                      {tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading ? (
                        <ProgressSpinner />
                      ) : (
                        <ProgressBarWithTitle
                          color="blue"
                          title="Challenges solved"
                          value={Math.round(
                            (selectLevelData?.solved /
                              selectLevelData?.totalTasks) *
                              100,
                          )}
                          caption={`${selectLevelData?.solved}/${selectLevelData?.totalTasks}`}
                        />
                      )}
                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                        flexItem
                      />
                      {tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading ? (
                        <ProgressSpinner />
                      ) : (
                        <ProgressBarWithTitle
                          color="blue"
                          title="Total Points earned"
                          value={Math.round(
                            (selectLevelData?.pointScored /
                              selectLevelData?.totalPoints) *
                              100,
                          )}
                          caption={`${selectLevelData?.pointScored}/${selectLevelData?.totalPoints}`}
                        />
                      )}
                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                        flexItem
                      />
                    </Box>
                  )}
                  endAdornment
                  showToggleButton={false}
                  content={() => (
                    <ProgressGraphs
                      data={tableRows}
                      barGraphLegendData={barGraphLegendData}
                      setBarGraphLegendData={setBarGraphLegendData}
                    />
                  )}
                />
              )}
              {tableRows.length > 0 && (
                <Box mt={1}>
                  <TasksTable
                    rows={tableRows}
                    teamBased={teamBased}
                    onRowClick={(value) => {
                      setActiveTaskOpenedId('');
                      setLastActiveTaskOpened(undefined);
                      refetchTaskOpened();
                      handleOpenDrawer(true);
                      const selectedTask = tableRows?.find(
                        (t) => t.id === value?.row?.id,
                      );
                      setActiveTask(selectedTask);
                      setTaskStatus(selectedTask.status);
                      setTaskAttempts(selectedTask.attempts);
                      const activityLabel =
                        getCurrentActivityLabel(value?.row?.id) || '';
                      setTaskActivity(activityLabel);
                      reset();
                    }}
                    activityLoading={
                      taskActivitiesUserLoading ||
                      tasksActivitiesAllUsersLoading
                    }
                    attemptsLoading={
                      tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Drawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Card style={{ overflowY: 'auto' }}>
          <CardHeader
            action={
              <IconButton
                onClick={() => {
                  setActiveTaskOpenedId('');
                  setLastActiveTaskOpened(undefined);

                  handleOpenDrawer(false);
                }}
              >
                <Close style={{ fontSize: 28 }} />
              </IconButton>
            }
            title={activeTask?.name}
          />
          <CardContent>
            <TaskDrawerHeaderParticipant
              activeTask={activeTask}
              taskStatus={
                tableRows?.find((t) => t.id === activeTask?.id)?.status
              }
              taskAttempts={taskAttempts}
            />
            {teamBased && !guided ? (
              <FdTab
                label={[
                  {
                    label: 'Challenge Details',
                    index: 0,
                    data: <CardDetails />,
                  },
                  {
                    label: 'Challenge Chat',
                    index: 1,
                    data: (
                      <ParticipantChallengeChat
                        challengeId={activeTask?.id}
                        assessmentId={
                          assessmentDataFiltered?.userAssessmentAssessmentId
                        }
                        team={teamMembersData?.getTeam || {}}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <CardDetails />
            )}
          </CardContent>
        </Card>

        <FdModal
          size="xl"
          title="Challenge Solution Walkthrough"
          description={
            <Box display="flex" alignItems="center">
              <Box
                style={{
                  width: '40%',
                  maxHeight: '400px',
                  overflowY: 'auto',
                  marginRight: '32px',
                }}
              >
                <FdMarkdownRender markdown={activeTask?.solutionSteps} />
              </Box>

              <div
                style={{ width: '40%' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: activeTask?.solutionVideo,
                }}
              />
              <script src="https://player.vimeo.com/api/player.js" />
            </Box>
          }
          confirm="Close"
          showDismiss={false}
          open={solutionModal}
          onConfirm={() => {
            setSolutionModal(false);
          }}
        />
      </Drawer>
    </BasePage>
  );
};

export default AssessmentTasks;
